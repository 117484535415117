import styled from 'styled-components';

export const Header = styled.div`
  width: 560px;
  height: 130px;
  margin: 0 auto;
  padding: 20px 0 0 0;
  text-align: center;
  @media (max-width: 870px) {
    width: 100%;
    height: auto;
    padding: 25px 0;
    padding-bottom: 0px;
  }
`;
export const HeaderContainer = styled.div`
  background: #00539f;
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00447c;
`;

export const ImageStyle = styled.img`
  width: 350px;
  height: auto;
  text-align: center;
  margin: 0 auto;
  @media (max-width: 870px) {
    width: 280px;
    float: none;
    padding: 0 0 10px 0;
    display: block;
    margin: 0 auto;
    text-align: center;
  }
`;
